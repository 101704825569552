<template>
  <div :key="update" style="background-color: #FAFAFA">
    <div style="overflow: scroll">
      <div
        v-for="(item, index) in dataMenu"
        :key="index"
        class="category-menu-movil"
        :style="
          item.color_fondo
            ? `background-color: ${item.color_fondo}; color: ${item.color_texto};`
            : ''
        "
      >
        <span
          v-if="!item.subMenu"
          @click="
            $emit('closeMenu', false)
            subMenu = false
            $router.push({
              name: item.routerName,
              params: {
                category: item.slug,
                nameCreative: `menu-${item.slug}`,
                slot: `menu`,
                id: `${item.slug}`,
                name: `menu ${item.slug}`,
              },
            })
          "
        >
          {{ item.descripcion }}
        </span>
        <div
          v-else
          @click="
            subMenu = true
            selectSubCategory = item
          "
        >
          {{ item.descripcion }}
          <img
            v-if="item.subMenu"
            class="ms-3"
            style="transform: rotate(-90deg)"
            src="@/assets/icons/arrow-select.svg"
            alt=""
          />
        </div>
      </div>
      <div class="category-menu-movil border-0">
        <router-link :to="{ name: 'CupoExpress' }" style="font-size: 1rem">
          Solicita tu cupo
        </router-link>
      </div>
      <div>
        <div class="d-block">
          <div class="category-menu-movil border-0">
            <router-link
              :to="{ name: 'Tiendas' }"
              class="item-nav-segundo-nivel"
              style="font-size: 1rem"
            >
              Encuentra una tienda
            </router-link>
          </div>
          <div class="category-menu-movil border-0">
            <router-link
              :to="{ name: 'FollowOrder' }"
              class="item-nav-segundo-nivel"
              style="font-size: 1rem"
            >
              Rastrear pedido
            </router-link>
          </div>
          <div class="category-menu-movil border-0">
            <a href="https://portalpagos.derek.co" class="pagar-cuota">
              Paga tu cuota
            </a>
          </div>
        </div>
        <div class="d-flex justify-content-center w-100 my-4">
          <img
            src="@/assets/icons/lovely.svg"
            class="px-3"
            style="height: 60px"
            alt="Logo categoría lovely"
            @click="
              $router.push({
                name: 'TiendaCategory',
                params: {
                  category: 'lovely',
                  nameCreative: `menu-lovely`,
                  slot: `menu`,
                  id: `lovely`,
                  name: `menu lovely`,
                },
              })
            "
          />
        </div>
        <div
          v-if="topbar"
          style="width: 100%; height: 85px; text-align: center;"
          v-html="topbar.html"
        ></div>
      </div>
    </div>
    <div
      v-if="selectSubCategory.title"
      id="sub-menu-movil"
      class="submenu-movil"
      :style="subMenu ? 'width: 100%; left: 0' : 'width: 0; left: -100%'"
    >
      <div
        class="title mb-4 d-flex"
        @click="
          subMenu = false
          selectSubCategory = {}
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13.504"
          height="23.619"
          viewBox="0 0 13.504 23.619"
        >
          <path
            id="Icon_ionic-ios-arrow-back"
            data-name="Icon ionic-ios-arrow-back"
            d="M4.071,11.813l8.937,8.93a1.688,1.688,0,0,1-2.391,2.384L.492,13.008a1.685,1.685,0,0,1-.049-2.327L10.61.492A1.688,1.688,0,0,1,13,2.876Z"
            fill="#131314"
          />
        </svg>
        <h2>{{ selectSubCategory.title }}</h2>
      </div>
      <div class="subcategory-menu">
        <a
          class="subcategori-menu-item"
          style="font-weight: bold; font-size: 1rem;"
          @click="
            $emit('closeMenu', false)
            subMenu = false
            $router.push({
              name: 'TiendaCategory',
              params: {
                category: selectSubCategory.slug,
                nameCreative: `menu-${selectSubCategory.slug}`,
                slot: `ver todo menu`,
                id: `${selectSubCategory.slug}`,
                name: `menu ${selectSubCategory.slug}`,
              },
            })
          "
        >
          Ver todo
        </a>
        <a
          v-for="(item, index) in selectSubCategory.subMenu"
          :key="index"
          class="subcategori-menu-item"
          style="font-size: 1rem"
          @click="
            $emit('closeMenu', false)
            subMenu = false
            $router.push({
              name: 'TiendaSubcategory',
              params: {
                category: selectSubCategory.slug,
                subcategory: item.slug,
                nameCreative: `menu-${item.slug}`,
                slot: `menu`,
                id: `${item.slug}`,
                name: `menu ${item.slug}`,
              },
            })
          "
        >
          {{
            item.descripcion.toLowerCase().replace(/^\w/, d => d.toUpperCase())
          }}
          <img
            v-if="item.destacado"
            class="novelity ps-2"
            src="@/assets/icons/novelty-icon.svg"
          />
        </a>
      </div>
      <div class="carousel-baner-submenu">
        <div>
          <carousel
            class="col-12 px-0 mx-0"
            :dots="false"
            :lazy-load="true"
            :nav="false"
            :loop="true"
            :responsive="{ 0: { items: 1, stagePadding: 50 } }"
          >
            <div
              v-for="(item, index) in selectSubCategory.categorias_destacadas"
              :key="index"
              class="container-carousel-subcategory-movil"
            >
              <a
                class="text-decoration-none"
                @click="
                  $emit('closeMenu', false)
                  subMenu = false
                  $router.push({
                    name: 'TiendaSubcategory',
                    params: {
                      category: selectSubCategory.slug,
                      subcategory: item.slug,
                      nameCreative: `menu-${item.slug}`,
                      slot: `carousel menu movil`,
                      id: `${item.slug}`,
                      name: `menu ${item.slug}`,
                    },
                  })
                "
              >
                <img :src="item.img" :alt="item.descripcion" />
                <div class="px-4">
                  <h3 class="title-banner-submenu-1">{{ item.descripcion }}</h3>
                  <h3 class="title-banner-submenu-2">{{ item.subName }}</h3>
                </div>
              </a>
            </div>
          </carousel>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import carousel from 'v-owl-carousel'
import { mapState } from 'vuex'
export default {
  components: { carousel },
  props: {
    dataMenu: {
      type: Array,
      required: true,
    },
    category: {
      type: String,
      required: false,
      default: '',
    },
    openMenu: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      selectSubCategory: {},
      subMenu: false,
      update: 0,
      imgsBanerSubmenu: [
        {
          img: '../assets/Banner_menu_1.jpg',
          name: 'Bufandas',
          subName: 'unicolor',
          slug: '',
        },
        {
          img: '../assets/Banner_menu_2.jpg',
          name: 'Buzos',
          subName: 'casual',
          slug: '',
        },
        {
          img: '../assets/Banner_menu_3.jpg',
          name: 'Chaquetas',
          subName: 'invierno',
          slug: '',
        },
      ],
    }
  },
  computed: {
    ...mapState('layouts', ['topbar']),
  },
  watch: {
    async subMenu() {
      await setTimeout(() => {
        this.update += 1
      }, 400)
    },
    openMenu() {
      if (!this.openMenu) {
        this.subMenu = false
      }
    },
  },
}
</script>
