<template>
  <div v-if="showOptionsUser" id="user-menu-derek" class="user-menu">
    <div class="user-menu-content">
      <div class="top-row-user-menu" />
      <div class="cupo-menu-movil">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22.621"
          height="19.081"
          viewBox="0 0 22.621 19.081"
        >
          <g
            id="Grupo_5396"
            data-name="Grupo 5396"
            transform="translate(-13.59 -52.057)"
          >
            <path
              id="Trazado_3370"
              data-name="Trazado 3370"
              d="M24.887,174.164a.316.316,0,0,1-.243-.115L13.914,161.035a.316.316,0,0,1,.243-.516H35.616a.316.316,0,0,1,.243.516L25.13,174.049A.316.316,0,0,1,24.887,174.164ZM14.827,161.15l10.06,12.2,10.06-12.2Z"
              transform="translate(-0.002 -103.277)"
              stroke="#000"
              stroke-width="0.5"
            />
            <path
              id="Trazado_3371"
              data-name="Trazado 3371"
              d="M138.094,174.171a.316.316,0,0,1-.292-.2l-5.319-13.014a.316.316,0,0,1,.292-.435h10.638a.315.315,0,0,1,.292.435l-5.319,13.014A.315.315,0,0,1,138.094,174.171Zm-4.849-13.014,4.849,11.865,4.849-11.865Z"
              transform="translate(-113.209 -103.283)"
              stroke="#000"
              stroke-width="0.5"
            />
            <path
              id="Trazado_3372"
              data-name="Trazado 3372"
              d="M35.614,57.874H14.155a.316.316,0,0,1-.259-.5l3.446-4.935a.316.316,0,0,1,.259-.135H32.168a.316.316,0,0,1,.259.135l3.446,4.935a.316.316,0,0,1-.259.5Zm-20.853-.631H35.009L32,52.938H17.766Z"
              transform="translate(0 0)"
              stroke="#000"
              stroke-width="0.5"
            />
            <path
              id="Trazado_3373"
              data-name="Trazado 3373"
              d="M143.441,57.874H132.8a.316.316,0,0,1-.284-.451l2.34-4.935a.316.316,0,0,1,.284-.181H141.1a.316.316,0,0,1,.284.181l2.34,4.935a.316.316,0,0,1-.284.451ZM133.3,57.242h9.64l-2.04-4.3h-5.56Z"
              transform="translate(-113.237 0)"
              stroke="#000"
              stroke-width="0.5"
            />
          </g>
        </svg>
        <router-link :to="{ name: 'CupoExpress' }"
          >Solicita tu cupo personal</router-link
        >
      </div>
      <div class="user-menu-nav" @click="$emit('clickOut', false)">
        <router-link :to="{ name: 'FollowOrder' }"
          >Rastrea tu pedido</router-link
        >
        <a href="https://portalpagos.derek.co">
          Paga tu cuota
        </a>
        <router-link :to="{ name: 'Returns' }">Devoluciones</router-link>
      </div>
      <div v-if="dataUser" class="d-flex">
        <router-link class="btn-user-menu p-0 d-flex" :to="{ name: 'Profile' }"
          ><button type="" @click="$emit('clickOut', false)">
            Perfil
          </button></router-link
        >
        <div class="divider" />
        <button
          class="btn-user-menu"
          type=""
          @click="
            logout()
            $emit('clickOut', false)
          "
        >
          Cerrar sesión
        </button>
      </div>
      <div v-else class="d-flex">
        <button
          class="btn-user-menu"
          type=""
          @click="
            $bvModal.show('modal-login')
            $emit('clickOut', false)
          "
        >
          Iniciar sesión
        </button>
        <div class="divider" />
        <button
          class="btn-user-menu"
          type=""
          @click="
            $bvModal.show('modal-register')
            $emit('clickOut', false)
          "
        >
          Registrarme
        </button>
      </div>
    </div>
    <div
      id="modal-bg-derek"
      class="modal-bg"
      :style="showOptionsUser ? 'display: flex' : 'display: none'"
      @click="$emit('clickOut', false)"
    />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  props: {
    showOptionsUser: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('user', ['dataUser']),
  },
  methods: {
    ...mapActions('user', ['logout']),
  },
}
</script>
