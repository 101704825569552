import $store from '@/store/index'
import router from '@/router'

const { products, cart, payment } = $store.state

async function getProductGA4() {
  return {
    currency: 'COP',
    value: parseInt(products.dataProduct.precio),
    items: [
      {
        item_name: products.dataProduct.titulo,
        item_id: products.dataProduct.producto_sku,
        price: parseInt(products.dataProduct.precio),
        item_brand: products.dataProduct.marca,
        affiliation: 'Derek',
        item_category: products.dataProduct.genero,
        item_category2: products.dataProduct.categoria,
        item_category3: products.dataProduct.subcategoria,
        promotion_name: products.dataProduct.descuento
          ? products.dataProduct.descuento
          : '',
        discount: parseInt(products.dataProduct.precio_antes)
          ? parseInt(products.dataProduct.precio_antes) -
            parseInt(products.dataProduct.precio)
          : 0,
        item_variant: router.app._route.query.color,
        quantity: 1,
        currency: 'COP',
      },
    ],
  }
}

async function getGroupProductsCartGA4(sku) {
  const findProduct = cart.productsCart.find(e => e.producto_sku == sku)
  let addProduct = {}
  if (findProduct) {
    addProduct = {
      items: [
        {
          item_name: findProduct.producto_titulo,
          item_id: findProduct.producto_sku,
          price: findProduct.precio,
          affiliation: 'Derek',
          item_brand: findProduct.marca,
          item_category: findProduct.categoria,
          item_category2: findProduct.subcategoria,
          item_variant: findProduct.referencia.color_id,
          quantity: 1,
        },
      ],
    }
  }
  return addProduct
}

async function getProductsCart() {
  return await getSchemaGA4Cart()
}

async function getInfoCheckout() {
  const objectEcommerce = {
    currency: 'COP',
    value: cart.dataCart.carrito_total,
    coupon: cart.dataCart.cupon.codigo,
    items: await getSchemaGA4Cart(),
  }
  return objectEcommerce
}

async function getInfoCheckoutShipping() {
  const objectEcommerce = {
    currency: 'COP',
    value: cart.dataCart.carrito_total,
    coupon: cart.dataCart.cupon.codigo,
    shipping_tier: 'Servientrega',
    items: await getSchemaGA4Cart(),
  }
  return objectEcommerce
}

async function getInfoPayment(typePayment) {
  const objectEcommerce = {
    currency: 'COP',
    value: cart.dataCart.carrito_total,
    coupon: cart.dataCart.cupon.codigo,
    payment_type: typePayment,
    items: await getSchemaGA4Cart(),
  }
  return objectEcommerce
}

async function getDataPurchase() {
  const objectEcommerce = {
    currency: 'COP',
    transaction_id: cart.dataCart.reference_sale,
    value: parseInt(cart.dataCart.carrito_total),
    coupon: cart.dataCart.cupon.codigo,
    shipping: parseInt(cart.dataCart.carrito_envio),
    items: await getSchemaGA4Cart(),
  }
  return objectEcommerce
}

async function getSchemaGA4Cart() {
  const productsGA4 = []
  await cart.productsCart.forEach(product => {
    if (productsGA4.find(e => e.item_id == product.producto_sku)) {
      productsGA4.filter(item => {
        if (
          item.item_id == product.producto_sku &&
          product.producto_aplica_estrategia == 0
        ) {
          item.quantity += 1
        }
        return item
      })
    } else {
      productsGA4.push({
        item_name: product.producto_titulo,
        item_id: product.producto_sku,
        price: parseInt(product.precio),
        affiliation: 'Stirpe',
        item_brand: product.marca,
        item_category: product.categoria,
        item_category2: product.subcategoria,
        item_variant: product.referencia.color_id,
        discount:
          parseInt(product.precio_antes) > 0
            ? parseInt(product.precio_antes) - parseInt(product.precio)
            : 0,
        promotion_name: product.descuento ? product.descuento : '',
        quantity: 1,
      })
    }
  })
  return productsGA4
}

async function getInfoCheckoutAnonymous() {
  const objectEcommerce = {
    currency: 'COP',
    value: payment.dataCartAnonymous.carrito.carrito_total,
    coupon: payment.dataCartAnonymous.carrito.cupon.codigo,
    items: await getSchemaGA4CartAnonymous(),
  }
  return objectEcommerce
}

async function getInfoCheckoutShippingAnonymous() {
  const objectEcommerce = {
    currency: 'COP',
    value: payment.dataCartAnonymous.carrito.carrito_total,
    coupon: payment.dataCartAnonymous.carrito.cupon.codigo,
    shipping_tier: 'Servientrega',
    items: await getSchemaGA4CartAnonymous(),
  }
  return objectEcommerce
}

async function getInfoPaymentAnonymous(typePayment) {
  const objectEcommerce = {
    currency: 'COP',
    value: payment.dataCartAnonymous.carrito.carrito_total,
    coupon: payment.dataCartAnonymous.carrito.cupon.codigo,
    payment_type: typePayment,
    items: await getSchemaGA4CartAnonymous(),
  }
  return objectEcommerce
}

async function getDataPurchaseAnonymous() {
  const objectEcommerce = {
    currency: 'COP',
    transaction_id: payment.dataCartAnonymous.carrito.reference_sale,
    value: payment.dataCartAnonymous.carrito.carrito_total,
    coupon: payment.dataCartAnonymous.carrito.cupon.codigo,
    shipping: payment.dataCartAnonymous.carrito.carrito_envio,
    items: await getSchemaGA4CartAnonymous(),
  }
  return objectEcommerce
}

async function getSchemaGA4CartAnonymous() {
  const productsGA4 = []
  await payment.dataCartAnonymous.productos.forEach(product => {
    if (productsGA4.find(e => e.item_id == product.producto_sku)) {
      productsGA4.filter(item => {
        if (
          item.item_id == product.producto_sku &&
          product.producto_aplica_estrategia == 0
        ) {
          item.quantity += 1
        }
        return item
      })
    } else {
      productsGA4.push({
        item_name: product.producto_titulo,
        item_id: product.producto_sku,
        price: parseInt(product.precio),
        affiliation: 'Stirpe',
        item_brand: product.marca,
        item_category: product.genero,
        item_category2: product.categoria_producto,
        item_category3: product.subcategoria_producto,
        item_variant: product.referencia.color_id,
        discount:
          parseInt(product.precio_antes) > 0
            ? parseInt(product.precio_antes) - parseInt(product.precio)
            : 0,
        promotion_name: product.descuento ? product.descuento : '',
        quantity: 1,
      })
    }
  })
  return productsGA4
}

export {
  getProductGA4,
  getGroupProductsCartGA4,
  getSchemaGA4Cart,
  getProductsCart,
  getInfoCheckout,
  getInfoCheckoutShipping,
  getInfoPayment,
  getDataPurchase,
  getInfoCheckoutAnonymous,
  getInfoCheckoutShippingAnonymous,
  getInfoPaymentAnonymous,
  getDataPurchaseAnonymous,
}
